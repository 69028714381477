<template>
  <el-card class="box-card">
    <el-table
        :data="list"
        style="width: 100%"
        row-key="id"
        border
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <el-table-column
          align="left"
          prop="id"
          label="ID"
          width="100">
      </el-table-column>
      <el-table-column
          align="center"
          prop="name"
          label="名字">
      </el-table-column>
      <el-table-column
          align="center"
          prop="country.name"
          label="所在国家">
      </el-table-column>
      <el-table-column
          align="center"
          prop="country.code"
          label="国家编码">
      </el-table-column>
      <el-table-column
          align="center"
          prop="code"
          label="行政区代码">
      </el-table-column>
      <!--      <el-table-column align="center" label="操作" width="100">-->
      <!--        <template #default="{ row }">-->
      <!--          <el-button type="text" @click="handleEdit(row)">编辑</el-button>-->
      <!--          <el-button type="text" @click="handleDelete(row)">删除</el-button>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </el-table>
    <Edit ref="Edit"/>
    <modifyEdit ref="modifyEdit"/>
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'
import modifyEdit from './components/modifyEdit'
import {apiGetAreaMsg} from '@/request/api'

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },
  },
  components: {Edit, modifyEdit},
  props: [],
  data() {
    return {
      selectRows: '',
      queryForm: {
        /*page: 1,
        pageSize: 10,*/
        name: '',
      },
      list: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    handleEdit(row) {
      if (row.id) {
        this.$refs['Edit'].showEdit(row)
      } else {
        this.$refs['Edit'].showEdit()
      }
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, async () => {
          const {code, message} = await doDelete({id: row.id})
          if (code == '200') {
            this.$message.success(message)
            await this.fetchData()
          } else {
            this.$message.error(message)
            await this.fetchData()
          }
        })
      } else {
        if (this.selectRows.length > 0) {
          const id = this.selectRows.map((item) => item.id).join()
          this.$confirm('你确定要删除选中项吗', null, async () => {
            const {code, message} = await doDelete({id})
            if (code == '200') {
              this.$message.success(message)
              await this.fetchData()
            } else {
              this.$message.error(message)
              await this.fetchData()
            }
          })
        } else {
          this.$message.error('未选中任何行!')
          return false
        }
      }
    },
    //获取区域无限极数据
    async fetchData() {
      this.listLoading = true
      apiGetAreaMsg(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data
        }
      })
      this.listLoading = false
    },
  }
}

</script>
